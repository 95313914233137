<template>
	<b-modal
		ref="createGeofenceModal"
		hide-footer
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm', 'md'].includes(windowWidth) ? 'xl' : 'lg'"
		:body-class="['xs'].includes(windowWidth) ? 'p-4' : ''"
		centered
		@shown="showMap = true"
		@hide="close">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('new_location') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close">
				×
			</button>
		</template>
		<div>
			<div class="row mb-3">
				<div class="col">
					<span>{{ translate('draw_on_map_instructions') }}</span>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col d-flex justify-content-center">
					<l-map
						v-if="showMap"
						ref="map"
						style="width: 100%; height: 360px;"
						:zoom="zoom"
						:options="{ zoomControl: false }"
						:center="center"
						@ready="handleMapReady">
						<l-control-zoom
							:zoom-in-title="translate('zoom_in')"
							:zoom-out-title="translate('zoom_out')"
							position="topleft" />
						<l-tile-layer
							:url="url"
							:attribution="attribution" />
						<l-circle
							v-if="isGeometrySet && !currentlyDrawing"
							:lat-lng="{ lat: geometry.latitude, lng: geometry.longitude }"
							:radius="geometry.radius" />
					</l-map>
					<div
						v-else
						style="width: 100%; height: 360px;"
						class="w-100 d-flex justify-content-center align-items-center">
						<i class="fa fa-fw fa-2x fa-spinner fa-pulse" />
					</div>
				</div>
			</div>
			<div class="mb-2">
				<div class="row">
					<div class="col">
						<input-text
							:id="'geofence_name'"
							:disabled="loading"
							required
							:setter-value="defaultName"
							:label="translate('save_location_as')"
							:placeholder="translate('location_name')"
							:errors="errors.name"
							type="text"
							@dataChanged="clearError('name'); name = $event" />
					</div>
				</div>
				<div
					v-if="address && name !== address"
					class="row no-gutters">
					<div class="col">
						<p class="text-medium mb-0">
							{{ translate('location_center') }}
						</p>
						<p class="text-medium font-italic">
							{{ address }}
						</p>
					</div>
				</div>
				<div
					v-if="geometry.radius"
					class="row no-gutters">
					<div class="col">
						<p class="text-medium mb-0">
							{{ translate('radius') }}:
							<span class="font-italic">
								{{ displayedRadius }}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col d-flex justify-content-end">
					<button
						:disabled="loading || isInputIncomplete"
						class="btn btn-primary"
						@click="save">
						<template v-if="!saving">
							{{ translate('save') }}
						</template>
						<template v-else>
							<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
						</template>
					</button>
				</div>
			</div>
		</div>
	</b-modal>
</template>
<script>
import {
	LCircle,
	LControlZoom,
	LMap,
	LTileLayer,
} from 'vue2-leaflet';
import 'leaflet-draw';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/dist/geosearch.css';
import InputText from '@/components/InputText';
import WindowSizes from '@/mixins/WindowSizes';
import { DEFAULT_MAP_CENTER } from '@/settings/Geofences';
import { Geofences as GeofencesMessages } from '@/translations';
import EventBus from '@/util/eventBus';
import Geofences from '@/util/Geofences';

// TO DO: Change leaflet-draw to leaflet-geoman
// Leaflet-draw, despite being an official leaflet library and not being deprecated, has not been updated in over 4 years and has almost 400 unresolved issues
// 	which is unexpected due to leaflet/leaflet being well maintained
// Leaflet-geoman is much more active and is well-maintained, but is not an official part of leaflet
// Sorry, noticed this when no time was left :(

// The location names displayed on the map come from OpenStreetMap's tile server.
// Tile servers send images already containing the rasterized text, so changing languages is expensive and require entire forks.
// So we're sticking with maps only in english until we are required to change to a paid service.

const { L } = window;

export default {
	name: 'GeofenceInput',
	messages: [GeofencesMessages],
	components: {
		InputText,
		LCircle,
		LControlZoom,
		LMap,
		LTileLayer,
	},
	mixins: [WindowSizes],
	data() {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			zoom: DEFAULT_MAP_CENTER.zoom,
			center: [DEFAULT_MAP_CENTER.lng, DEFAULT_MAP_CENTER.lat],

			name: '',
			address: '',
			geometry: {},

			defaultName: '',
			createGeofence: new Geofences(),
			addressData: new Geofences(),
			currentlyDrawing: false,
			showMap: false, // for not rendering the map until the modal is open
			errors: {},
		};
	},
	computed: {
		saving() {
			return this.createGeofence.data.loading;
		},
		estimatingAddress() {
			return this.addressData.data.loading;
		},
		loading() {
			return this.saving || this.estimatingAddress;
		},
		isInputIncomplete() {
			return !this.name || !this.address || !this.isGeometrySet;
		},
		isGeometrySet() {
			const { latitude, longitude, radius } = this.geometry;
			return ![latitude, longitude, radius].some((value) => value === null);
		},
		displayedRadius() {
			return `${+this.geometry.radius.toFixed(1)}m`;
		},
	},
	watch: {
		address(newVal, oldVal) {
			if (!this.name || this.name === oldVal) {
				this.defaultName = newVal;
			}
		},
	},
	mounted() {
		this.clearGeometryData();
		EventBus.$on('showCreateGeofenceModal', () => {
			this.open();
		});
		EventBus.$on('hideCreateGeofenceModal', () => {
			this.close();
		});
	},
	beforeDestroy() {
		EventBus.$off('showCreateGeofenceModal');
		EventBus.$off('hideCreateGeofenceModal');
	},
	methods: {
		initiateDrawConfigs() {
			// Check the drawLocal declaration in https://github.com/Leaflet/Leaflet.draw/blob/d5dd11781c2e07f9e719308e504fe579000edf54/src/Leaflet.draw.js
			//   for available Leaflet Draw options and their defaults
			const { toolbar, handlers } = L.drawLocal.draw;

			toolbar.actions.title = this.translate('cancel_drawing');
			toolbar.actions.text = this.translate('cancel');
			toolbar.buttons.circle = this.translate('draw_circle');
			handlers.circle.tooltip.start = this.translate('circle_tooltip_start');
			handlers.circle.radius = this.translate('radius');
			handlers.simpleshape.tooltip.end = this.translate('circle_tooltip_end');
		},
		handleMapReady() {
			const map = this.$refs.map.mapObject;
			map.addControl(this.drawControl());											// Add circle tool
			map.addControl(this.searchControl());										// Add geosearch
			map.on(L.Draw.Event.DRAWSTART, () => { this.currentlyDrawing = true; });	// Handle draw start
			map.on(L.Draw.Event.DRAWSTOP, () => { this.currentlyDrawing = false; });	// Handle draw end
			map.on(L.Draw.Event.CREATED, this.handleCreated);							// Handle circle creation
			this.rerenderMap();															// Fix rendering issues due to using it in a modal
		},
		searchControl() {
			return new GeoSearchControl({
				provider: new OpenStreetMapProvider({				// Use OSM as search API provider (1000 reqs/day/project) and set locale
					params: { 'accept-language': this.language },	// Set results locale
				}),
				showMarker: false,									// Don't place a marker on the map, only center map on clicked result
				style: 'bar',										// Other style is 'button', but css seems broken
				searchLabel: this.translate('enter_address'),		// Set label manually for locale
			});
		},
		drawControl() {
			const drawControl = new L.Control.Draw({ draw: true });

			L.DrawToolbar.include({
				getModeHandlers(map) {
					return [{
						enabled: true,
						handler: new L.Draw.Circle(map),
					}];
				},
			});

			return drawControl;
		},
		rerenderMap() {
			this.$refs.map.mapObject.invalidateSize();
		},
		clearGeometryData() {
			this.setGeometryData(null, null, null);
		},
		setGeometryData(latitude, longitude, radius) {
			this.geometry = { latitude, longitude, radius };
		},
		save() {
			this.createGeofence.createGeofence({ ...this.geometry, name: this.name, address: this.address }).then((response) => {
				this.$emit('saved', { geofenceId: response.response.id });
				this.close();
			}).catch((errors) => {
				this.errors = errors.errors;
			});
		},
		open() {
			this.$refs.createGeofenceModal.show();
		},
		close() {
			this.$emit('close');
			this.resetData();
			this.$refs.createGeofenceModal.hide();
		},
		resetData() {
			this.name = '';
			this.defaultName = '';
			this.address = '';
			this.clearGeometryData();
			this.currentlyDrawing = false;
			this.showMap = false;
			this.errors = {};
		},
		clearError(field) {
			this.errors = Object.fromEntries(Object.entries(this.errors).filter(([fieldName]) => fieldName !== field));
		},
		handleCreated(e) {
			const { layer } = e;

			// Private properties that start with an underscore throw linting errors.
			// However we need them so we will ignore those warnings.
			// eslint-disable-next-line no-underscore-dangle
			const { _latlng: latlng, _mRadius: radius } = layer;
			const { lat, lng } = latlng;
			this.setGeometryData(lat, lng, radius);
			this.getEstimatedAddressFromCoords(lat, lng);
		},
		getEstimatedAddressFromCoords(lat, lng) {
			this.addressData.getAddressFromCoords(lat, lng).then((response) => {
				this.address = response.data.display_name;
			});
		},
	},
};
</script>
