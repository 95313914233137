const scheduled = 'scheduled';
const now = 'now';

export const API_KEY = process.env.VUE_APP_TINY_MCE_API_KEY;
export const TITLE_MAX_LENGTH = 30;
export const TEXT_MAX_LENGTH = 190;

export const MEDIA_TYPES = [
	'none',
	'mobile_redirection',
	'url',
	// 'image',
];

export const MOBILE_SECTIONS = [
	'main',
	'alerts',
	'announcements',
	'media',
	'videos',
	'notifications',
	'store',
];

export const IMAGES_EXT = ['jpg', 'jpeg', 'png', 'gif'];

export const SCHEDULED_TYPES = [
	now,
	scheduled,
];
export const CANCELABLE_STATUSES = [scheduled];
export default {};
