import {
	GET_PUSH_NOTIFICATIONS,
	GET_PUSH_NOTIFICATIONS_RECIPIENTS,
	CREATE_PUSH_NOTIFICATION,
	CANCEL_PUSH_NOTIFICATION,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();
class PushNotifications {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getPushNotifications(options) {
		const { method, endpoint } = GET_PUSH_NOTIFICATIONS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRecipients(options) {
		const { method, endpoint } = GET_PUSH_NOTIFICATIONS_RECIPIENTS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createPushNotification(options) {
		const { method, endpoint } = CREATE_PUSH_NOTIFICATION;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options)
			.then((response) => {
				this.data = response;
				return this.data;
			})
			.catch((errors) => Promise.reject(errors))
			.finally(() => {
				this.loading = false;
				return null;
			});
	}

	cancelPushNotification(id, payload) {
		const { method, endpoint } = CANCEL_PUSH_NOTIFICATION;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default PushNotifications;
