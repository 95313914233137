import {
	GET_GEOFENCES,
	CREATE_GEOFENCE,
	GET_ADDRESS_FROM_COORDS,
} from '@/config/endpoint';
import { thirdParty } from '@/config/axios';
import Req from './AxiosRequest';

class Geofences {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getGeofences() {
		const { method, endpoint } = GET_GEOFENCES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createGeofence(params) {
		const { method, endpoint } = CREATE_GEOFENCE;
		return this.data[method](endpoint, params);
	}

	getAddressFromCoords(lat, lng) {
		const { method, endpoint } = GET_ADDRESS_FROM_COORDS;
		return thirdParty[method](endpoint(lat, lng)).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Geofences;
