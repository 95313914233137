<template>
	<div
		:class="hasError(fieldName) ? 'is-invalid' : ''"
		:style="'height: auto'"
		class="border-0">
		<div class="row">
			<div class="col-12">
				<label>{{ translate(fieldName) }}</label>
			</div>
			<div class="col-xl-2 col-sm-4">
				<select
					:id="fieldName + 'SelectedYear'"
					v-model="selectedYear"
					type="text"
					class="form-control w-100 my-1"
					name="year"
					@change="updateDays()">
					<option
						value=""
						selected
						disabled>
						{{ translate('year') }}
					</option>
					<option
						v-for="year in years"
						:key="year"
						:value="year">
						{{ year }}
					</option>
				</select>
			</div>
			<div class="col-xl-2 col-sm-4">
				<select
					:id="fieldName + 'selectedMonth'"
					v-model="selectedMonth"
					type="text"
					class="form-control w-100 my-1"
					name="month"
					@change="updateDays()">
					<option
						value=""
						selected
						disabled>
						{{ translate('month') }}
					</option>
					<option
						v-for="month in months"
						:key="month"
						:value="month">
						{{ month }}
					</option>
				</select>
			</div>
			<div class="col-xl-2 col-sm-4">
				<select
					:id="fieldName + 'selectedDay'"
					v-model="selectedDay"
					type="text"
					class="form-control w-100 my-1"
					name="day">
					<option
						value=""
						selected
						disabled>
						{{ translate('day') }}
					</option>
					<option
						v-for="day in days"
						:key="day"
						:value="day">
						{{ day }}
					</option>
				</select>
			</div>
			<div class="col-xl-2 col-sm-4">
				<select
					:id="fieldName + 'selectedHour'"
					v-model="selectedHour"
					type="text"
					class="form-control w-100 my-1"
					name="hour">
					<option
						value=""
						selected
						disabled>
						{{ translate('hour') }}
					</option>
					<option
						v-for="hour in hours"
						:key="hour"
						:value="hour">
						{{ hour }}
					</option>
				</select>
			</div>
			<div class="col-xl-2 col-sm-4">
				<select
					:id="fieldName + 'selectedMinute'"
					v-model="selectedMinute"
					type="text"
					class="form-control w-100 my-1"
					name="minute">
					<option
						value=""
						selected
						disabled>
						{{ translate('minute') }}
					</option>
					<option
						v-for="minute in minutes"
						:key="minute"
						:value="minute">
						{{ minute }}
					</option>
				</select>
			</div>
			<div
				v-if="selectedDate"
				class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
				role="alert">
				{{ selectedDate }}
			</div>
			<template v-if="hasError(fieldName)">
				<span
					v-for="error in errors.errors[fieldName]"
					:key="error"
					class="custom-invalid-feedback animated fadeIn px-2"
					v-text="error" />
			</template>
		</div>
	</div>
</template>
<script>
import {
	Time as timeTranslations,
} from '@/translations';
import { YMDHMS_FORMAT, YM_FORMAT, SCHEDULE_START_YEAR } from '@/settings/Dates';
import { TIMEZONE_NAME } from '@/settings/General';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	messages: [
		timeTranslations,
	],
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			errors: this.errorsImport,
			years: [],
			months: [],
			days: [],
			hours: [],
			minutes: [],
			selectedYear: '',
			selectedMonth: '',
			selectedDay: '',
			selectedHour: '',
			selectedMinute: '',
			selectedDate: '',
			YMDHMS_FORMAT,
			YM_FORMAT,
			TZN: TIMEZONE_NAME,
		};
	},
	computed: {
		date() {
			if (this.selectedYear !== '' && this.selectedMonth !== '' && this.selectedDay !== '' && this.selectedHour !== '' && this.selectedMinute !== '') {
				return this.createDate(this.selectedYear, this.selectedMonth, this.selectedDay, this.selectedHour, this.selectedMinute);
			}
			return '';
		},
	},
	watch: {
		date(newVal) {
			this.selectedDate = newVal;
		},
		errorsImport(value) {
			this.errors = value;
		},
	},
	mounted() {
		this.populateTime();
	},
	methods: {
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') { return true; }
			return false;
		},
		// Dates
		populateTime() {
			const startYear = this.$moment(`${SCHEDULE_START_YEAR}`).year();
			for (let i = 0; i <= 59; i += 1) {
				if (i <= 20) this.years.push(startYear + i);
				if (i < 10) {
					this.hours.push(`0${i}`);
					this.minutes.push(`0${i}`);
					if (i > 0) this.months.push(`0${i}`);
				} else {
					if (i <= 23) this.hours.push(`${i}`);
					if (i <= 12) this.months.push(`${i}`);
					this.minutes.push(`${i}`);
				}
			}
		},
		updateDays() {
			const year = this.selectedYear;
			const month = this.selectedMonth;
			const days = [];

			const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
			for (let i = 1; i <= maxDaysInMonth; i += 1) {
				if (i < 10) {
					days.push(`0${i}`);
				} else {
					days.push(`${i}`);
				}
			}
			this.days = days;
			this.selectedDay = '01';
		},
		createDate(year, month, day, hour, minute) {
			let date = '';
			if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
				date = this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
			}
			this.$emit('dateChanged', date);

			return date;
		},
	},
};
</script>
