<template>
	<div>
		<b-row>
			<b-col cols="12">
				<b-form
					@submit.prevent="preparePayload"
					@keydown="clearError($event.target.name)"
					@change="clearError($event.target.name)">
					<b-row
						v-if="!isCorporateWithCountry">
						<b-col
							cols="12"
							sm="12"
							md="6"
							lg="6">
							<b-form-group id="formSelector">
								<label
									for="country_selector"
									class="label">
									{{ translate('country_selector') }}
									<span class="text-danger">*</span>
								</label>
								<multiselect
									id="countries"
									v-model="selector"
									:errors="errors.errors['countries']"
									:options="countries"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('country_placeholder')"
									:class="hasError('filters') ? 'is-invalid' : ''"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="countries"
									@select="clearError('filters')"
									@remove="clearError('filters')">
									<template
										slot="selection"
										slot-scope="{ values, search, isOpen }">
										<span
											v-if="values.length && !isOpen"
											class="multiselect__single">
											{{ translate('options_selected', { count: values.length }) }}
										</span>
									</template>
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
								<template v-if="hasError('filters')">
									<span
										v-for="error in errors.errors['filters']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
								<div
									v-if="selector.length > 0"
									role="alert"
									class="a mb-3 mt-2 p-1 px-3 text-left text-small countries-alert">
									{{ getCountriesNames(selector) }}
								</div>
							</b-form-group>
						</b-col>
						<b-col
							cols="12"
							sm="12"
							md="2"
							lg="2"
							class="mx-auto d-flex justify-content-center align-items-center">
							<p>
								<b>{{ translate('selected_countries', {recipients: notificationRecipients,}) }}</b>
							</p>
						</b-col>
					</b-row>
					<b-row
						v-if="isCorporateWithCountry"
						class="mx-auto justify-content-center text-center">
						<b-col cols="12">
							<p>
								<b>{{ translate('selected_countries', {recipients: notificationRecipients,}) }}</b>
							</p>
						</b-col>
						<b-col cols="12">
							<template v-if="hasError('filters')">
								<span
									v-for="error in errors.errors['filters']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-col>
					</b-row>
					<b-form-group
						id="titleInput"
						:errors="errors.errors['title']">
						<label
							for="title"
							class="label">
							{{ translate('title') }}
							<span class="text-danger">*</span>
						</label>
						<b-form-input
							id="title"
							v-model="title"
							:placeholder="translate('title')"
							:class="hasError('title') ? 'is-invalid' : ''"
							name="title"
							type="text"
							:maxlength="titleMaxLength"
							@dataChanged="title = $event" />
						<template v-if="hasError('title')">
							<span
								v-for="error in errors.errors['title']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
					<b-form-group
						id="textArea"
						:errors="errors.errors['text']">
						<label
							for="text"
							class="label">
							{{ translate('text') }}
							<span class="text-danger">*</span>
						</label>
						<editor
							id="text"
							v-model="text"
							:api-key="apiKeyEditor"
							:init="{
								height: 150,
								plugins: 'emoticons',
								toolbar: 'emoticons',
								toolbar_location: 'top',
								menubar: false }"
							:class="hasError('text') ? 'is-invalid' : ''"
							model-events="change"
							output-format="text"
							@onChange="clearError('text')" />
						<b-form-text id="message_type">
							{{ translate('text_max_length_message', {'text_max': textMaxLength}) }}
						</b-form-text>
						<template v-if="hasError('text')">
							<span
								v-for="error in errors.errors['text']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
					<b-row>
						<b-col
							cols="12"
							sm="12"
							md="12"
							lg="12">
							<b-form-group id="geofences">
								<label
									for="geofence"
									class="label">
									{{ translate("location") }}
								</label>
								<div class="row no-gutters">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : 'pr-2'"
										class="col-12 col-md d-flex align-items-center">
										<select
											id="geofence"
											v-model="geofenceId"
											type="text"
											class="form-control w-100 my-1"
											name="type">
											<option :value="null">
												{{ translate('none') }}
											</option>
											<option
												v-for="geofence in geofences"
												:key="`geofence_${geofence.id}`"
												:value="geofence.id">
												{{ geofence.attributes.name }}
											</option>
										</select>
									</div>
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''"
										class="col-12 col-md-auto d-flex align-items-center">
										<div
											v-if="$can('geofences', 'create')"
											class="btn btn-primary pointer"
											@click="showGeofenceModal = true">
											<i class="fa fa-plus" /> {{ translate("add") }}
										</div>
									</div>
								</div>
								<div
									v-if="selectedGeofence"
									class="row no-gutters">
									<div class="col pl-2">
										<span class="text-medium font-italic">
											<i class="fa fa-map-marker-alt" /> {{ selectedGeofence.attributes.address }}
										</span>
									</div>
								</div>
								<template v-if="hasError('geofence_id')">
									<span
										v-for="error in errors.errors['geofence_id']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col
							cols="12"
							sm="12"
							md="3"
							lg="3">
							<b-form-group id="mediaType">
								<label
									for="media_type"
									class="label">
									{{ translate("media_type") }}
									<a
										v-b-tooltip.hover
										:href="infoLink"
										target="_blank"
										:title="translate('media_type_info')"
										class="text-decoration-none link-primary mr-2">
										<i class="fas fa-info-circle" />
									</a>
								</label>
								<select
									id="media_type"
									v-model="mediaType"
									type="text"
									class="form-control w-100"
									name="media_type"
									@change="clearError('url'); clearError('mobile_redirection')">
									<option
										v-for="mediaTypeOption in mediaTypes"
										:key="mediaTypeOption"
										:value="mediaTypeOption">
										{{ translate(mediaTypeOption) }}
									</option>
								</select>
								<template v-if="hasError('media_type')">
									<span
										v-for="error in errors.errors['media_type']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col
							v-if="mediaType === 'mobile_redirection'"
							cols="12"
							sm="12"
							md="3"
							lg="3"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''">
							<b-form-group
								label-for="mobile_section">
								<slot name="label">
									<label>
										{{ translate('mobile_section') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<select
									id="mobile_section"
									v-model="mobileSection"
									type="text"
									class="form-control w-100"
									name="mobile_section"
									@change="clearError('mobile_redirection.mobile_section')">
									<option
										v-for="mobileSectionOption in mobileSections"
										:key="mobileSectionOption"
										:value="mobileSectionOption">
										{{ translate(mobileSectionOption) }}
									</option>
								</select>
								<template v-if="hasError('mobile_redirection.mobile_section')">
									<span
										v-for="error in errors.errors['mobile_redirection.mobile_section']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col
							v-if="mediaType === 'mobile_redirection' && (mobileSection === 'media' || mobileSection === 'videos')"
							cols="12"
							sm="12"
							md="3"
							lg="3"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''">
							<b-form-group label-for="mobile_redirection_language">
								<slot name="label">
									<label>
										{{ translate('language') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<select
									id="mobile_redirection_language"
									v-model="mobileRedirectionLanguage"
									type="text"
									class="form-control w-100"
									name="media_category"
									@change="clearError('mobile_redirection.mobile_redirection_language')">
									<option
										v-for="languageOption in availableLanguages"
										:key="languageOption.value"
										:value="languageOption.value">
										{{ translate(languageOption.text) }}
									</option>
								</select>
							</b-form-group>
						</b-col>
						<b-col
							v-if="mediaType === 'mobile_redirection' && (mobileSection === 'media' || mobileSection === 'videos')"
							cols="12"
							sm="12"
							md="3"
							lg="3"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''">
							<b-form-group label-for="media_id">
								<slot name="label">
									<label>
										{{ translate('media_item') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<select
									id="media_category"
									v-model="mediaId"
									type="text"
									class="form-control w-100"
									name="media_category"
									:disabled="mediaLoading"
									@change="clearError('mobile_redirection')">
									<option
										v-for="mediaSectionOption in mediaSections"
										:key="mediaSectionOption.id"
										:value="mediaSectionOption.id">
										{{ mobileSection === 'videos' ? mediaSectionOption.attributes.title : mediaSectionOption.attributes.name }}
									</option>
								</select>
								<template v-if="hasError('mobile_redirection')">
									<span
										v-for="error in errors.errors['mobile_redirection']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col
							v-if="mediaType === 'url'"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''">
							<!--<b-form-group
								v-if="mediaType === 'image'"
								:errors="errors.errors['image']"
								class="media-files"
								label-for="image">
								<slot name="label">
									<label>
										{{ translate('image') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-file
									id="image"
									ref="image"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:class="hasError('image') ? 'is-invalid' : ''"
									class="text-truncate"
									name="image"
									type="file" />
								<b-form-text id="image">
									{{ translate('max', { size: '10' }) }} {{ translate('allowed_ext', { ext: imgExt }) }}
								</b-form-text>
								<template v-if="hasError('image')">
									<span
										v-for="error in errors.errors['image']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>-->
							<b-form-group
								v-if="mediaType === 'url'"
								label-for="url">
								<slot name="label">
									<label>
										{{ translate('url') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-input
									id="url"
									v-model="url"
									:placeholder="translate('url')"
									:class="hasError('url') ? 'is-invalid' : ''"
									name="url"
									type="text" />
								<template v-if="hasError('url')">
									<span
										v-for="error in errors.errors['url']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col
							cols="12"
							sm="12"
							md="3"
							lg="3">
							<b-form-group id="releaseDate">
								<label
									for="scheduling_type"
									class="label">
									{{ translate("scheduling_type") }}
									<span class="text-danger">*</span>
								</label>
								<select
									id="type"
									v-model="type"
									type="text"
									class="form-control w-100 my-1"
									name="type">
									<option
										v-for="scheduleType in scheduledTypes"
										:key="scheduleType"
										:value="scheduleType">
										{{ translate(scheduleType) }}
									</option>
								</select>
								<template v-if="hasError('type')">
									<span
										v-for="error in errors.errors['type']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col
							v-if="type === 'scheduled'"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : ''">
							<date-time-picker
								field-name="release_date"
								:errors-import="errors"
								@dateChanged="dateChange($event)" />
						</b-col>
					</b-row>
					<b-form-group
						id="passwordInput"
						:errors="errors.errors['password']">
						<label
							for="password"
							class="label">
							{{ translate('password') }}
							<span class="text-danger">*</span>
						</label>
						<input-password
							id="password"
							v-model="password"
							:placeholder="translate('password')"
							:input-class="`${hasError('password') ? 'is-invalid' : ''} form-control`"
							toggle-class="form-control"
							name="password" />
						<template v-if="hasError('password')">
							<span
								v-for="error in errors.errors['password']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
					<b-button
						:disabled="Object.keys(errors.errors).length > 0 || loading || mediaLoading"
						class="float-right"
						variant="primary"
						type="submit">
						<template v-if="loading">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							{{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('send') }}
						</template>
					</b-button>
				</b-form>
			</b-col>
		</b-row>
		<geofence-input
			@saved="handleNewGeofence"
			@close="showGeofenceModal = false" />
	</div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import DateTimePicker from '@/components/DateTimePicker';
import GeofenceInput from '@/components/GeofenceInput';
import InputPassword from '@/components/InputPassword';
import WindowSizes from '@/mixins/WindowSizes';
import { corporate } from '@/settings/Roles';
import {
	API_KEY,
	TITLE_MAX_LENGTH,
	TEXT_MAX_LENGTH,
	SCHEDULED_TYPES,
	MEDIA_TYPES,
	MOBILE_SECTIONS,
	IMAGES_EXT as imgExt,
} from '@/settings/PushNotifications';
import {
	PushNotifications as messages, Countries, Common, MediaLibrary, Languages,
} from '@/translations';
import Country from '@/util/Country';
import EventBus from '@/util/eventBus';
import Geofences from '@/util/Geofences';
import PushNotifications from '@/util/PushNotifications';
import MediaCategories from '@/util/MediaLibrary';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { LANGUAGES as videoLanguages } from '@/settings/HighlightVideos';
import { LANGUAGES as mediaLanguages } from '@/settings/Media';
import HighlightVideo from '@/util/HighlightVideo';

export default {
	name: 'NewPushNotification',
	messages: [messages, Countries, Common, MediaLibrary, Languages],
	components: {
		GeofenceInput,
		InputPassword,
		Multiselect,
		editor: Editor,
		DateTimePicker,
	},
	mixins: [WindowSizes],
	data() {
		return {
			notification: new PushNotifications(),
			country: new Country(),
			countries: [],
			countriesOnly: [],
			selector: [],
			title: '',
			text: '',
			password: '',
			mediaType: '',
			imgExt,
			url: '',
			mobileSection: '',
			mediaId: null,
			type: '',
			scheduledDate: '',
			alert: new this.$Alert(),
			notificationRecipients: 0,
			corporate,
			apiKeyEditor: API_KEY,
			titleMaxLength: TITLE_MAX_LENGTH,
			textMaxLength: TEXT_MAX_LENGTH,
			mediaTypes: MEDIA_TYPES,
			mobileSections: MOBILE_SECTIONS,
			scheduledTypes: SCHEDULED_TYPES,
			showGeofenceModal: false,
			geofencesData: new Geofences(),
			geofenceId: null,
			categories: new MediaCategories(),
			videos: new HighlightVideo(),
			mobileRedirectionLanguage: null,
			availableLanguages: [],
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.notification.data.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.notification.data.errors.errors = newErrors;
			},
		},
		loading() {
			return !!this.notification.data.loading;
		},
		mediaLoading() {
			return !!this.categories.data.loading || !!this.videos.data.loading;
		},
		isCorporateWithCountry() {
			return !!(this.corporate.includes(this.$user.details().type) && this.$user.details().country);
		},
		scheduledType() {
			return this.type;
		},
		releaseDate() {
			return this.scheduledDate;
		},
		geofences() {
			try {
				return this.geofencesData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		mediaSections() {
			try {
				if (this.mobileSection === 'videos') {
					return this.videos.data.response.data.data.videos;
				}
				return this.categories.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		selectedGeofence() {
			if (this.geofenceId) {
				return this.geofences.find((geofence) => +geofence.id === +this.geofenceId);
			}
			return null;
		},
	},
	watch: {
		selector() {
			const select = this.selector;
			if (select.length) {
				// no need to reassign 'select' if it's already equal to all of the countries
				if (this.handleSelectAll(select) === true && select !== this.countriesOnly) {
					this.selector = this.countriesOnly;
				}
			}
			this.refreshRecipients();
		},
		geofenceId() {
			this.refreshRecipients();
		},
		language() {
			try {
				this.initializeMultiselect();
				if (this.selector.length > 0) {
					setTimeout(() => {
						this.selector = this.selector.map((item) => ({
							value: item.value,
							text: this.translate(item.value.toLowerCase()),
						}));
					}, 300);
				}
				return this.selector;
			} catch (error) {
				return this.selector;
			}
		},
		showGeofenceModal(newVal) {
			if (newVal) {
				EventBus.$emit('showCreateGeofenceModal');
			}

			if (!newVal) {
				EventBus.$emit('hideCreateGeofenceModal');
			}
		},
		mobileSection(newVal) {
			// eslint-disable-next-line default-case
			switch (newVal) {
			case 'media':
				this.availableLanguages = this.getMediaLanguages();
				this.mobileRedirectionLanguage = this.availableLanguages[0].value;
				this.getMediaItems();
				break;
			case 'videos':
				this.availableLanguages = this.getVideoLanguages();
				this.mobileRedirectionLanguage = this.availableLanguages[0].value;
				this.getHighlightVideos();
				break;
			}
		},
		mobileRedirectionLanguage() {
			// eslint-disable-next-line default-case
			switch (this.mobileSection) {
			case 'media':
				this.getMediaItems();
				break;
			case 'videos':
				this.getHighlightVideos();
				break;
			}
		},
	},
	mounted() {
		this.getGeofences();
		this.initializeMultiselect();
		this.mediaType = 'none';
		this.mobileSection = 'main';
		this.type = 'now';
	},
	methods: {
		initializeMultiselect() {
			this.country.getCountries().then((response) => {
				const countryCode = response.map((item) => item.attributes);
				this.countries = countryCode.map((item) => ({
					value: item.code,
					text: this.translate(item.code.toLowerCase()),
				}));
				this.countriesOnly = JSON.parse(JSON.stringify(this.countries));
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			});
		},
		getGeofences() {
			return this.geofencesData.getGeofences();
		},
		getMediaItems() {
			const params = { view_type: 'card', language: this.mobileRedirectionLanguage };
			this.categories.getPushMediaLibrary(params).then((result) => {
				this.mediaId = result[0].id;
			});
		},
		getHighlightVideos() {
			const params = { all: 1, language: this.mobileRedirectionLanguage };
			this.videos.getPushHighlightVideos(params).then((result) => {
				this.mediaId = result.videos[0].id;
			});
		},
		refreshRecipients() {
			const options = {};
			if (this.selector.length) {
				const countryFilter = { countries: this.getCountriesIso(this.selector) };
				options.filters = JSON.stringify(countryFilter);
			}

			if (this.geofenceId) options.geofence_id = this.geofenceId;

			if (Object.keys(options).length) {
				this.notification.getRecipients(options).then((response) => {
					const validNumbers = response.response.valid_numbers;
					this.notificationRecipients = validNumbers;
				});
			} else this.notificationRecipients = 0;
		},
		handleNewGeofence(e) {
			this.getGeofences().then(() => {
				this.geofenceId = e.geofenceId;
			});
		},
		clearError(field) {
			if (field) {
				delete this.notification.data.errors.errors[field];
				this.notification.data.errors.errors = {
					...this.errors.errors,
				};
			}
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		dateChange(date) {
			if (date !== this.scheduledDate) {
				this.clearError('release_date');
			}
			this.scheduledDate = date;
		},
		handleSelectAll(select) {
			// 'this.countries' array can contain 'Select all option' object
			// 'this.countriesOnly' does not change and it never contains 'Select all option' object, only countries
			let allSelected = false;
			const selectedCountries = select.map((country) => country.value);
			const numberOfCountries = this.countriesOnly.length;
			const selectAllExists = (this.countries[0].value === 'ALL');
			// If:  1) 'select all' option is chosen		OR		2) if you chose all countries manually
			// delete 'select all' option (as all countries are already chosen)
			if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
				if (selectAllExists) {
					this.countries.shift();
				}
				allSelected = true;
			}
			// If 1) 'selected all' option is deleted		2) not all countries are selected
			// then insert 'select all' into the dropdown options.
			if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
			return allSelected;
		},
		getCountriesIso(select) {
			const selectedIsoCodes = select.map((countryAttributes) => {
				if (countryAttributes.value === 'ALL') {
					return this.countries.map((country) => country.value);
				}
				return countryAttributes.value;
			}).toString();
			return selectedIsoCodes;
		},
		getCountriesNames(select) {
			const selectedNames = select.map((countryAttributes) => {
				if (countryAttributes.value === 'ALL') {
					return this.countries.map((country) => country.text);
				}
				return countryAttributes.text;
			}).join(', ');
			return selectedNames;
		},
		clearFields() {
			this.selector = [];
			this.text = '';
			this.title = '';
			this.password = '';
			if (this.countries[0].value !== 'ALL') {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
		},
		preparePayload() {
			const countryFilter = { countries: this.getCountriesIso(this.selector) };
			if (this.isCorporateWithCountry) countryFilter.countries = this.$user.details().country.iso_code_2;

			const formData = new FormData();
			formData.set('title', this.title.toString());
			formData.set('text', this.text.toString());
			formData.set('type', this.type.toString());
			formData.set('release_date', this.releaseDate.toString());
			formData.set('password', this.password.toString());
			formData.set('filters', JSON.stringify(countryFilter));
			formData.set('media_type', this.mediaType);

			if (this.mediaType === 'url') {
				formData.set('url', this.url);
			} else if (this.mediaType === 'mobile_redirection') {
				let mobileRedirection = { mobile_section: this.mobileSection };
				if (this.mobileSection === 'media' || this.mobileSection === 'videos') {
					mobileRedirection = { ...mobileRedirection, media_id: this.mediaId };
				}
				formData.set('mobile_redirection', JSON.stringify(mobileRedirection));
			// } else if (this.mediaType === 'image') {
			// 	if (typeof this.$refs.image !== 'undefined') {
			// 		const [File] = this.$refs.image.$refs.input.files;
			// 		formData.set('image', File);
			// 	}
			}
			if (this.geofenceId) {
				formData.set('geofence_id', this.geofenceId);
			}
			this.sendPushNotification(formData);
		},
		sendPushNotification(payload) {
			this.notification.data.loading = true;
			this.notification.createPushNotification(payload).then(() => {
				this.alert.toast('success', this.translate('notification_sent'));
				this.clearFields();
				this.$router.push({ name: 'PushNotifications' });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => {
				this.notification.data.loading = false;
			});
		},
		getVideoLanguages() {
			return videoLanguages.map((languageCode) => ({
				value: languageCode,
				text: `${languageCode}_language`,
			}));
		},
		getMediaLanguages() {
			return mediaLanguages.map((languageCode) => ({
				value: languageCode,
				text: `${languageCode}_language`,
			}));
		},
	},
};
</script>
<style scoped>
	.countries-alert {
		background-color: rgb(249 167 65 / 20%);
		border-color: #73818f;
		color: #7d6d6d;
		border-radius: 5px;
	}
</style>
